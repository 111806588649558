import React from "react";
import { Col, Row } from "react-bootstrap";
import { DiJavascript1, DiReact, DiNodejs, DiGit } from "react-icons/di";
import {
  SiFirebase,
  SiNextdotjs,
  SiWordpress,
  SiMongodb,
  SiTailwindcss,
  SiStyledcomponents,
  SiAmazonaws,
  SiGraphql,
  SiDocker,
  SiApachekafka,
  SiPostgresql,
  SiRedis
} from "react-icons/si";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <DiJavascript1 />
        <span className="purple" style={{ fontSize: "18px", display: "block" }}>
          Javascript
        </span>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <DiNodejs />
        <span className="purple" style={{ fontSize: "18px", display: "block" }}>
          Node Js
        </span>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiAmazonaws />
        <span className="purple" style={{ fontSize: "18px", display: "block" }}>
          Aws
        </span>
      </Col>    
      
      <Col xs={4} md={2} className="tech-icons">
        <DiReact />
        <span className="purple" style={{ fontSize: "18px", display: "block" }}>
          React Js
        </span>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiNextdotjs />
        <span className="purple" style={{ fontSize: "18px", display: "block" }}>
          Next Js
        </span>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiDocker />
        <span className="purple" style={{ fontSize: "18px", display: "block" }}>
          Docker
        </span>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiApachekafka />
        <span className="purple" style={{ fontSize: "18px", display: "block" }}>
          Apache Kafka
        </span>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiRedis />
        <span className="purple" style={{ fontSize: "18px", display: "block" }}>
          Redis
        </span>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiPostgresql />
        <span className="purple" style={{ fontSize: "18px", display: "block" }}>
          Postgres
        </span>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiMongodb />
        <span className="purple" style={{ fontSize: "18px", display: "block" }}>
          MongoDb
        </span>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiGraphql />
        <span className="purple" style={{ fontSize: "18px", display: "block" }}>
          Graphql
        </span>
      </Col>
      
      <Col xs={4} md={2} className="tech-icons">
        <SiTailwindcss />
        <span className="purple" style={{ fontSize: "18px", display: "block" }}>
          Tailwind Css
        </span>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiStyledcomponents />
        <span className="purple" style={{ fontSize: "18px", display: "block" }}>
          Styled Components
        </span>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiGit />
        <span className="purple" style={{ fontSize: "18px", display: "block" }}>
          Git & Github
        </span>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiFirebase />
        <span className="purple" style={{ fontSize: "18px", display: "block" }}>
          Firebase
        </span>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiWordpress />
        <span className="purple" style={{ fontSize: "18px", display: "block" }}>
          Wordpress
        </span>
      </Col>
    </Row>
  );
}

export default Techstack;
