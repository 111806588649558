import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
// import leaf from "../../Assets/Projects/leaf.png";
// import emotion from "../../Assets/Projects/emotion.png";
// import editor from "../../Assets/Projects/codeEditor.png";
// import chatify from "../../Assets/Projects/chatify.png";
// import suicide from "../../Assets/Projects/suicide.png";
// import bitsOfCode from "../../Assets/Projects/blog.png";
import amazon from "../../Assets/Projects/m-amazon2.png";
import hulu from "../../Assets/Projects/m-hulu.png";
import linkedin from "../../Assets/Projects/m-linkedIn-2.png";
import airbnb from "../../Assets/Projects/m-airbnb.png";
import mui from "../../Assets/Projects/m-mui.png";
import medium from "../../Assets/Projects/m-medium.png";
import immigration from "../../Assets/Projects/m-immigration.png";
import prepter from "../../Assets/Projects/prepter.png";
import automatrix from "../../Assets/Projects/automatrix.png";
import tempMail from "../../Assets/Projects/tempMail.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={prepter}
              isBlog={false}
              title="Prepter - AI Mocks"
              description="Prepter is a SaaS product specializing in providing AI powered Mock tests for your interview preparations. Utilizes Gemeni 1.5 Flash model to generate mocks. It's built with Nextjs, Drizzle ORM, Postgress with Clerk authentication and Razorpay payment integration end to end."
              // ghLink="https://github.com/soumyajit4419/Chatify"
              demoLink="https://prepter.mayank9.com"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={automatrix}
              isBlog={false}
              title="Automatrix - App Deploy"
              description="Automatrix is another Full stack SaaS application which deploys your ReactJs app faster and provides dynamic subdomain link. It's core utilizes AWS, ECS Fargate, Docker, Kakfa, CLickhouse columnar database to store realtime logs, S3, EC2 instances, ReverseProxy and built with NextJs & NodeJs. "
              // ghLink="https://github.com/soumyajit4419/Plant_AI"
              demoLink="https://Mayank9.in"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={tempMail}
              isBlog={false}
              title="Disposable Mail Service"
              description="This  is another Full Stack app SaaS application to provide disposable/temporary email address to receive OTPs or to receive links. It comes in handy when you don't want to give your personal email address everywhere. At it's core it functions with a custom NodeJs SMTP mail server running in AWS EC2 instance with dynamoDb database to store emails."
              // ghLink="https://github.com/soumyajit4419/Bits-0f-C0de"
              demoLink="https://mail.mayank9.in"
            />
          </Col>

          {/* // ------------------------ new ------------------------------------------------------------- */}

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={amazon}
              isBlog={false}
              title="Amazone Clone"
              description="This is a clone of Amazon website with NextAuth authentication where user can add products, proceed to checkout and see our orders in realtime. The tech stacks used in it are - ReactJs, NextJS, Redux, TailwindCSS, Webhooks, Firebase, Stripe Checkout"
              // ghLink="https://github.com/soumyajit4419/Chatify"
              demoLink="https://amazon-clone-nine-steel.vercel.app/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={airbnb}
              isBlog={false}
              title="Airbnb-Clone"
              description="This is a clone of Airbnb website where the UI is very appealing and fully responsive. Also user can search for flights through a date-picker and see the results with map locations. The tech stacks used in it are - ReactJS, NextJS, TailwindCSS, Mapbox."
              // ghLink="https://github.com/soumyajit4419/Plant_AI"
              demoLink="https://airbnb-clone-mayank027.vercel.app/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={hulu}
              isBlog={false}
              title="Hulu-Clone"
              description="This is a clone of Hulu website with NextJS and SSR. Also the UI is fully responsive and support 4k resolution. Here use can browse through various movie categories and see realtime data from TMDB API. The tech stacks used in it are - ReactJS, NextJS, TMDB API and TailwindCSS."
              // ghLink="https://github.com/soumyajit4419/Bits-0f-C0de"
              demoLink="https://hulu-clone-nine-omega.vercel.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={linkedin}
              isBlog={false}
              title="LinkedIn-Clone"
              description="This is a clone of LinkedIn website with Google authentication where user can post pictures and video links with some description in real time. The tech stacks used in it are - ReactJS, Styled Components, Redux, Firebase."
              // ghLink="https://github.com/soumyajit4419/Editor.io"
              demoLink="https://linkedin-clone-15557.web.app"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={mui}
              isBlog={false}
              title="Social Ui - MUI"
              description="This is UI of common Social media feed designed with ReactJs and Material UI. ALso it has dark mode feature with a fully responsive UI. Hosted the Static build file on Hostinger sub domain. Check it out now!"
              // ghLink="https://github.com/soumyajit4419/AI_For_Social_Good"
              demoLink="http://materialui.mayankvinera.com"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={medium}
              isBlog={false}
              title="Medium-Clone"
              description="This is a clone of Medium website with NextJS and made with TypeScript. The tech stacks used in it are - ReactJS, NextJS, TailwindCSS, TypeScript, Sanity CMS for backend and ISR. Check it out Now!"
              // ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
              demoLink="https://medium2-0-clone-liard.vercel.app/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={immigration}
              isBlog={false}
              title="Gates&Buffett-Immigration"
              description="This is a Business website which I made for a client to help and grow their Business. The tech stacks used in it are - Wordpress and hosted on Hostinger."
              // ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
              demoLink="https://gatesandbuffettimmigration.com"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
