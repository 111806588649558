import React from "react";
import { Container, Row } from "react-bootstrap";
// import Button from "react-bootstrap/Button";
import Particle from "../Particle";
import data from "../../Assets/blog.json";
// import { AiOutlineExport } from "react-icons/ai";
// import { AiOutlineSelect } from "react-icons/ai";
import { FaLocationArrow } from "react-icons/fa";

function Blog() {
  return (
    <div>
      <Container fluid className="blog-section">
        {/* <Particle /> */}
        {data?.map((blog, index) => (
          <div key={index} className="blog-article">
            <div className="article-image">
              <img src={blog.logo} alt={blog.website} />
            </div>
            <div className="article-content">
              <h3>{blog.title}</h3>
              <p>{blog.description}</p>
              <a href={blog.link} target="_blank" className="article-button">
                Read More <FaLocationArrow size={16} />
              </a>
            </div>
          </div>
        ))}
      </Container>
    </div>
  );
}

export default Blog;
