import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Mayank Mishra </span>
            from <span className="purple"> Pune, India.</span>
            <br />I am a Passionate 
            <span className="purple"> Full Stack Developer </span>
            and 
            <span className="purple"> AWS Certified.</span>
             <br />
             Did 
            my Computer  Engineering from GTU. 
            Worked at both 
            <span className="purple"> Service & Product </span>
            based companies and now exploring the world
            of 
            <span className="purple"> building scalable apps.</span>
            {/* Currently working as<br /> a Freelancer and developing side Projects. */}
            <br />
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Playing Games
            </li>
            <li className="about-activity">
              <ImPointRight /> Reading Books
            </li>
            <li className="about-activity">
              <ImPointRight /> Exploring New Tech
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "A jack of all trades is a master of none, but oftentimes better than a master of one."{" "}
          </p>
          <footer className="blockquote-footer">Robert Greene</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
